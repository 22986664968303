import Prompt from "../../layout/Prompt";
import React, {useRef, useState} from "react";

import {DayPicker} from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import './TimePicker.scss';
import dayjs from "dayjs";

// caching...
const today = dayjs();
let cacheSelectedDay = today.toDate();
let cacheSelectedHours = Math.max(6, today.get('hour'));
let cacheSelectedMinutes = (parseInt((today.get('minutes') + 7.5)/15) * 15) % 60;

const TimePicker = ({
  onClose,
  onPick
}) => {

  const promptRef = useRef();
  const today = dayjs();
  const tomorrow = dayjs().add(1, 'day');
  const twoDaysAfter = dayjs().add(2, 'day');

  const [selectedDay, setSelectedDay] = useState(cacheSelectedDay);
  const [selectedHours, setSelectedHours] = useState(cacheSelectedHours);

  //https://stackoverflow.com/a/4968292/534862
  const [selectedMinutes, setSelectedMinutes] = useState(cacheSelectedMinutes);

  const handleDayClick = day => {
    setSelectedDay(day);
    cacheSelectedDay = day;
  };

  const handleMinutes = m => {
    setSelectedMinutes(m);
    cacheSelectedMinutes = m;
  }

  const handleHours = h => {
    setSelectedHours(h);
    cacheSelectedHours = h;
  }

  const handleCancel = () => {
    promptRef.current.close();
  };

  const handleOK = () => {
    selectedDay.setHours(selectedHours);
    selectedDay.setMinutes(selectedMinutes);
    selectedDay.setSeconds(0);
    onPick(dayjs(selectedDay));
    promptRef.current.close();
  };

  return <Prompt
    ref={promptRef}
    className={"TimePicker"}
    title={"Pick a time"}
    onClose={onClose}
  >
    <div className={"quick-dates"}>
      <button className={"btn" + (selectedDay.toDateString() === today.toDate().toDateString() ? ' active' : '')} onClick={e => setSelectedDay(today.toDate())}>Today</button>
      <button className={"btn" + (selectedDay.toDateString() === tomorrow.toDate().toDateString() ? ' active' : '')} onClick={e => setSelectedDay(tomorrow.toDate())}>Tomorrow</button>
      <button className={"btn" + (selectedDay.toDateString() === twoDaysAfter.toDate().toDateString() ? ' active' : '')} onClick={e => setSelectedDay(twoDaysAfter.toDate())}>2 Days After</button>
    </div>
    <div className={"day-picker"}>
      <DayPicker
        mode="single"
        selected={selectedDay}
        onDayClick={handleDayClick}
        fromDate={dayjs().toDate()}
        toDate={dayjs().add(4, 'week').toDate()}
      />
    </div>
    <div className={"time-picker"}>
      <div className={"hour"}>
        <div className={"label"}>Hours</div>
        <div className={"options"}>
          {[...Array(18).keys()].map(i => {
            const hours = i + 6;
            const label = hours % 12 === 0 ? 12 : hours % 12;
            const a = hours >= 12 ? 'pm' : 'am';
            return <button key={"hour-" + hours} className={"btn" + (selectedHours === hours ? ' active' : '')} onClick={e => handleHours(hours)}>{label}{a}</button>
          })}
        </div>
      </div>
      <div className={"minutes"}>
        <div className={"label"}>Minutes</div>
        <div className={"options"}>
          {[...Array(4).keys()].map(i => {
            const minutes = i * 15;
            return <button key={"minutes-" + minutes} className={"btn" + (selectedMinutes === minutes ? ' active' : '')} onClick={e => handleMinutes(minutes)}>:{minutes.toString().padStart(2, '0')}</button>
          })}
        </div>
      </div>
    </div>

    <div className={"actions"}>
      <button className={"btn btn-cancel btn-text"} onClick={handleCancel}>Cancel</button>
      <button className={"btn btn-confirm"} onClick={handleOK}>OK</button>
    </div>
  </Prompt>;
};

export default TimePicker;
