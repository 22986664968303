import './Filter.scss';

const Filter = ({filter, onRemoveFilter}) => {

  const handleClick = () => {
    onRemoveFilter(filter);
  }

  return <div className={"Filter"} onClick={handleClick}>
    <span className={"label"}>{filter.name}</span>
    <span className={"icon material-icons"}>close</span>
  </div>
};

export default Filter;