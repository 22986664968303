import axios from 'axios';
import {refresh} from "./auth";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
const LOCALSTORAGE_USER_ACCESS_TOKEN = 'cb-access-token';

let userAccessToken = window.localStorage.getItem(LOCALSTORAGE_USER_ACCESS_TOKEN);

const setAxiosDefaultHeader = () => {
  axios.interceptors.request.use(config => {
    if (userAccessToken) {
      config.headers['Authorization'] = 'Bearer ' + userAccessToken;
    }
    return config;
  })
}

export const setUserSessionToken = s => {
  window.localStorage.setItem(LOCALSTORAGE_USER_ACCESS_TOKEN, s);
  userAccessToken = s;
  setAxiosDefaultHeader();
}

setAxiosDefaultHeader();

axios.interceptors.response.use(res => res, error => {
  if (error.response.status === 400 && error.response.data.message === 'password_invalid') {
    alert('Have you changed your password recently?\n\nIf so, do contact us. System will log you out now.');
    apiLogout();
    window.location.reload();
  }

  const originalRequest = error.config;
  if (userAccessToken && error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    return refresh().then(res => {
      setUserSessionToken(res.data.access_token);
      return axios(originalRequest);
    }).catch(() => {
      alert('Failed to refresh token. You have signed out due to inactivity. Please sign in again.');
      apiLogout();
      window.location.reload();
    });
  }

  return Promise.reject(error);
});

/**
 * @param {string} uri
 * @returns {string}
 */
function url(uri) {
  return API_ENDPOINT + '/' + uri.replace(/^\//, '');
}

function defaultHeaders() {
  return {
    'Authorization': 'Bearer ' + userAccessToken
  };
}

export function hasAccessToken() {
  return !!userAccessToken;
}

export function apiLogout() {
  userAccessToken = null;
  window.localStorage.removeItem(LOCALSTORAGE_USER_ACCESS_TOKEN);
}

/**
 * Paged stuff!
 * @param {string} uri
 * @param {object} params
 * @param {object} headers
 * @returns {Promise<any>}
 */
export class Paged {
  constructor(uri, params = {}, headers = {}) {
    this.uri = uri;
    this.params = params;
    this.headers = headers;
    this.perPage = 25;
    this.page = 1;
  }

  setPerPage(p = 25) {
    this.perPage = p;
    return this;
  }

  setPage(p = 1) {
    this.page = p;
    return this;
  }

  setParams(params) {
    this.params = {...params, ...this.params};
    return this;
  }

  clone() {
    const paged = new Paged(this.uri, {...this.params}, {...this.headers});
    paged.page = this.page;
    paged.perPage = this.perPage;
    return paged;
  }

  get() {
    return get(this.uri, {
      ...this.params,
      per_page: this.perPage,
      page: this.page
    }, this.headers);
  }
}

export function paged(uri, params = {}, headers = {}) {
  return new Paged(uri, params, headers);
}

/**
 * Get stuff!
 * @param {string} uri
 * @param {object} params
 * @param {object} headers
 * @returns {Promise<any>}
 */
export function get(uri, params = {}, headers = {}) {
  return axios.get(url(uri), {
    params,
    headers: {...defaultHeaders(), ...headers}
  });
}

/**
 * Post stuff!
 * @param {string} uri
 * @param {object} data
 * @param {object} headers
 * @returns {Promise<any>}
 */
export function post(uri, data = {}, headers = {}) {
  return axios.post(url(uri), data, {
    headers: {...defaultHeaders(), ...headers}
  });
}

/**
 * Put stuff!
 * @param {string} uri
 * @param {object} data
 * @param {object} headers
 * @returns {Promise<any>}
 */
export function put(uri, data = {}, headers = {}) {
  return axios.put(url(uri), data, {
    headers: {...defaultHeaders(), ...headers}
  });
}

/**
 * Delete stuff!
 * @param {string} uri
 * @param {object} data
 * @param {object} headers
 * @returns {Promise<any>}
 */
export function del(uri, data = {}, headers = {}) {
  return axios.delete(url(uri), {
    headers: {...defaultHeaders(), ...headers}
  });
}
