import Class from "../../shared/Class";
import dayjs from "dayjs";
import {useEffect, useState} from "react";
import Day from "../../layout/Day";
import './SearchResult.scss';

const SearchResult = ({loading, classes, onChange}) => {

  const [days, setDays] = useState([]);

  useEffect(() => {
    const mapping = [];
    const days = [];
    classes.forEach(cls => {
      const startTime = dayjs(cls.start_time);
      const date = startTime.format('YYYY-MM-DD');
      mapping[date] = mapping[date] || [];
      mapping[date].push(cls);
    });
    const sortedKeys = Object.keys(mapping).sort();
    sortedKeys.forEach(key => {
      const obj = dayjs(key, 'YYYY-MM-DD');
      return days.push({
        classes: mapping[key],
        date: key,
        obj,
        day: obj.day()
      })
    });
    setDays(days);
  }, [classes]);

  const handleClassChange = cls => {
    // find the class and update it and rerender
    let replaced = false;
    days.forEach(day => {
      const idx = day.classes.findIndex(c => c.id === cls.id);
      if (idx >= 0) {
        day.classes.splice(idx, 1, cls);
        replaced = true;
      }
    });
    if (replaced) {
      setDays([...days]);
      onChange(cls);
    }
  };

  const handleSchedule = (cls, id, once, suspend, suspendOnce) => {
    cls.schedule_id = id;
    cls.schedule_once = once;
    cls.schedule_suspend = suspend;
    cls.schedule_suspend_once = suspendOnce;
    handleClassChange(cls);
  };

  const handleRemoveSchedule = cls => {
    handleSchedule(cls, null, false, false, false);
  };


  return <div className={"SearchResult" + (loading ? ' loading' : '')}>
    <div className={"holder"}>
      {days.map(day => <Day key={day.date} title={<>
        <div className={"day"}>{day.obj.format('dddd')}</div>
        <div className={"date"}>{day.obj.format('D MMM')}</div>
      </>}>
        {day.classes.map(cls => <Class
          onChange={handleClassChange}
          key={cls.id}
          cls={cls}
          name={cls.name}
          staffName={cls.instructors.map(i => i.name).join(', ')}
          clubName={cls.club_name}
          clubId={cls.club_id}
          startTime={dayjs(cls.start_time)}
          endTime={dayjs(cls.end_time)}
          classMissing={false}
          showQuota={true}
          remaining={cls.leftover - cls.waitlist_count}
          capacity={cls.capacity}
          collapsible={true}
          attended={cls.attended}

          once={cls.schedule_once}
          suspend={cls.schedule_suspend}
          suspendOnce={cls.schedule_suspend_once}
          scheduleId={cls.schedule_id}

          onSchedule={(id, once, suspend, suspendOnce) => handleSchedule(cls, id, once, suspend, suspendOnce)}
          onRemoveSchedule={() => handleRemoveSchedule(cls)}
        />)}
      </Day>)}
    </div>
  </div>
};

export default SearchResult;