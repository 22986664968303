import './Class.scss';
import dayjs from "dayjs";
import BookButton from "./BookButton";
import ScheduleButton from "./ScheduleButton";
import {useState} from "react";
const Class = ({
  name, startTime, endTime, staffName, clubName,
  showQuota = false,
  capacity, remaining,
  classMissing = false,
  attended = false,
  collapsible,
  clubId,

  showSchedule = true,
  once = false, suspend = false,
  suspendOnce = false, scheduleId = null,
  onSchedule = () => {},
  onRemoveSchedule = () => {},
  showRemoveScheduleConfirmation = false,

  cls,
  schedule,

  onChange = () => {}
}) => {

  const now = dayjs(),
    duration = Math.floor((endTime.unix() - startTime.unix())/60);

  const started = now.isAfter(startTime);
  const [expanded, setExpanded] = useState(!!schedule || !collapsible || (cls && (cls.schedule_id || cls.booked || cls.waitlisted)));
  const [entering, setEntering] = useState(false);
  const [leaving, setLeaving] = useState(false);

  const [loading, setLoading] = useState(false);

  const toggleExpand = () => {
    if (collapsible) {
      if (!expanded) {
        setExpanded(true);
        setEntering(true);
        setTimeout(() => {
          setEntering(false);
        }, 200);
      } else {
        setLeaving(true);
        setTimeout(() => {
          setLeaving(false);
          setExpanded(false);
        }, 200);
      }
    }
  }

  const handleBookButtonChange = cls => {
    onChange(cls);
  };

  const handleBookButtonLoading = loading => {
    setLoading(loading);
  }

  const handleScheduleButtonLoading = loading => {
    setLoading(loading);
  }

  return <div className={
    "class" +
    (suspend ? ' suspended' : '') +
    (started ? ' started' : '') +
    (loading ? ' loading' : '') +
    (expanded ? ' expanded' : '') +
    (leaving ? ' leaving' : '') +
    (entering ? ' entering' : '') +
    (cls && cls.waitlisted ? ' waitlisted' : '') +
    (cls && cls.booked ? ' booked' : '')
  }>
    <div className={"class-time"}>
      {startTime && startTime.format('HH:mm')}
    </div>
    <div className={"class-body"}>
      {classMissing && <div className={"class-missing"}>Class missing. Consider re-schedule.</div>}
      <div className={"class-name-and-staff"} onClick={toggleExpand}>
        <div className={"class-name"}>
          {attended && <span className={"class-attended material-icons"}>verified</span>}
          <span className={"name"}>{name}</span>
        </div>
        <div className={"class-staff-name"}>{staffName || '-'}</div>
      </div>
      <div className={"class-detail"}>
        <div className={"class-location-date-time"} onClick={toggleExpand}>
          <div className={"class-location"}>{clubName}</div>
          <div className={"class-date-time"}>
            {startTime.format('ddd')}, {startTime.format('HH:mm')} - {endTime.format('HH:mm')}
            &nbsp;({duration} mins)
          </div>
        </div>
        <div className={"class-actions"}>
          {showSchedule && <ScheduleButton
            loading={loading}
            onLoading={handleScheduleButtonLoading}
            clubId={clubId}
            day={startTime.day()}
            clubName={clubName}
            className={name}
            startTime={startTime.format('HH:mm:ss')}
            endTime={endTime.format('HH:mm:ss')}
            once={once}
            suspend={suspend}
            suspendOnce={suspendOnce}
            scheduleId={scheduleId}
            onSchedule={onSchedule}
            onRemoveSchedule={onRemoveSchedule}
            showRemoveConfirmation={showRemoveScheduleConfirmation}
          />}
          {cls && !started && <BookButton cls={cls}
                              loading={loading}
                              onChange={handleBookButtonChange}
                              onLoading={handleBookButtonLoading}
          />}
        </div>
      </div>
    </div>
    {showQuota && <div className={"class-quota"} onClick={toggleExpand}>
      {remaining === 0 ? <div className={"class-quota-full"}>FULL</div> : <>
        <div className={"class-quota-remaining" + (remaining < 0 ? ' negative' : '')}>{remaining}</div>
        <div className={"class-quota-divider"}>/</div>
        <div className={"class-quota-capacity"}>{capacity}</div>
      </>}
    </div>}
    {collapsible && <div className={"class-collapsible-icon"}>
      <span className={"material-icons"}>{expanded ? 'unfold_less' : 'unfold_more'}</span>
    </div>}
  </div>
};
export default Class;