import './SearchCandidates.scss';
import SearchCandidate from "./SearchCandidate";
const SearchCandidates = ({candidates, onCandidate}) => {

  // Regroup them into subTopic, Instructor and Clubs
  const clubs = [];
  const instructors = [];
  const subTopics = [];

  candidates.forEach(candidate => {
    switch(candidate.type) {
      case 'club': clubs.push(candidate); break;
      case 'instructor': instructors.push(candidate); break;
      case 'subtopic': subTopics.push(candidate); break;
      default: break;
    }
  });

  return <div className={"SearchCandidates"}>
    {clubs.length > 0 && <div className={"candidate-group"}>
      <div className={"candidate-group-name"}>Clubs</div>
      <div className={"candidate-items"}>
        {clubs.map(candidate => <SearchCandidate key={candidate.id} candidate={candidate} onCandidate={onCandidate} />)}
      </div>
    </div>}

    {subTopics.length > 0 && <div className={"candidate-group"}>
      <div className={"candidate-group-name"}>Class Types</div>
      <div className={"candidate-items"}>
        {subTopics.map(candidate => <SearchCandidate key={candidate.id} candidate={candidate} onCandidate={onCandidate} />)}
      </div>
    </div>}

    {instructors.length > 0 && <div className={"candidate-group"}>
      <div className={"candidate-group-name"}>Instructors</div>
      <div className={"candidate-items"}>
        {instructors.map(candidate => <SearchCandidate key={candidate.id} candidate={candidate} onCandidate={onCandidate} />)}
      </div>
    </div>}
  </div>
};

export default SearchCandidates;

