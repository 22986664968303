import AppBar from "../../layout/AppBar";
import './SearchAppBar.scss';
import Filter from "./Filter";
import {forwardRef, useImperativeHandle, useRef} from "react";

/**
 * All mode means, when it's true, when tap on the input it clears as a whole.
 * Useful when the user choose to show "All Clubs" "All Instructors"... etc and
 * we want it to feels good when they tap on the input bar.
 */
const SearchAppBar = forwardRef(({value, onValue, onRefresh, onTimePicker, filters, onRemoveFilter, allMode = false, loading = false}, ref) => {

  const inputRef = useRef();
  const appBarRef = useRef();

  useImperativeHandle(ref, () => ({

    getHeight() {
      return appBarRef.current.offsetHeight;
    },

    focusSearch() {
      inputRef.current.focus();
    }
  }));

  const handleSearch = e => {
    onValue(e.target.value);
  }

  const handleClear = e => {
    onValue('');
    setTimeout(() => {
      inputRef.current.focus();
    }, 200);
  };

  const handleInputClick = e => {
    if (allMode) {
      // clears immediately
      onValue('');
    }
  }

  return <AppBar className={"SearchAppBar" + (loading ? ' loading' : '')} ref={appBarRef}>
    <div className={"search-control"}>
      <div className={"search"}>
        <div className={"search-icon"}><span className={"material-icons"}>search</span></div>
        {value && <div className={"clear-icon"} onClick={handleClear}><span className={"material-icons"}>close</span></div>}
        <form autoComplete={"chrome-off"}  onSubmit={e => e.preventDefault()}>
          <input type="text"
                 name={"m"}
                 className={"search-input"} placeholder={"Search anything..."}
                 autoCapitalize={'off'}
                 spellCheck={'false'}
                 autoComplete={'off'}
                 autoCorrect={'off'}
                 value={value}
                 onChange={handleSearch}
                 onClick={handleInputClick}
                 ref={inputRef}
                 disabled={loading}
          />
        </form>
      </div>
      <div className={"time-picker"}>
        <button className={"btn"} onClick={onTimePicker}>
          <span className="material-icons icon">event</span>
        </button>
      </div>
    </div>
    {filters.length > 0 && <div className={"filters-section"}>
      <div className={"refresh"}>
        <button className={"btn"} onClick={onRefresh}>
          <span className={"icon material-icons"}>refresh</span>
        </button>
      </div>
      <div className={"filters"}>
        {filters.map(f => <Filter key={f.id} filter={f} onRemoveFilter={onRemoveFilter} /> )}
      </div>
    </div>}
  </AppBar>
});

export default SearchAppBar;
