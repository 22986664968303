import Tab from "../layout/Tab";
import './HomeTab.scss';
import Schedules from "./home/Schedules";
import UpcomingClasses from "./home/UpcomingClasses";
import {useContext} from "react";
import {DashboardContext} from "../../Contexts";

const HomeTab = props => {

  const {loading, onRefresh} = useContext(DashboardContext);

  const handleRefresh = () => {
    if (!loading) onRefresh();
  };

  return <Tab {...props} className={"home-tab"} actions={<>
    <button className={"app-bar-action" + (loading ? ' loading' : '')} onClick={handleRefresh}>
      <span className="material-icons">refresh</span>
    </button>
  </>}{...props}>
    <div className={"section"}>
      <div className={"section-header"}>
        Confirmed / Waitlisted Classes
      </div>
      <div className={"section-body"}>
        <UpcomingClasses />
      </div>
    </div>
    <div className={"section"}>
      <div className={"section-header"}>
        Scheduled Classes
      </div>
      <div className={"section-body"}>
        <Schedules />
      </div>
    </div>

  </Tab>
}

export default HomeTab;