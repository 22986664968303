import HomeTab from "./components/tabs/HomeTab";
import SearchTab from "./components/tabs/SearchTab";
import SettingsTab from "./components/tabs/SettingsTab";

export const gym = {
  bookInAdvanceHours: 46,
}

export const themes = {
  'dark': 'Dark',
  'light': 'Light',
  'default': 'System Default'
};

export const defaultColor = '#03a9f4';

export const colors = [
  '#f44336',
  '#f57c00',
  '#fbc02d', // too bright :(
  '#4caf50',
  '#03a9f4',
  '#ab47bc',
  //'#9575cd',
];

export const tabs = [
  {name: 'home', label: 'Home', icon: 'home', tab: HomeTab, active: true},
  {name: 'search', label: 'Search', icon: 'travel_explore', tab: SearchTab},
  {name: 'settings', label: 'Settings', icon: 'manage_accounts', tab: SettingsTab},
];