import {useContext, useEffect, useState} from "react";
import Class from "../../shared/Class";
import {DashboardContext} from '../../../Contexts';
import dayjs from "dayjs";
import {gym} from "../../../config";
import Day from "../../layout/Day";
import Empty from "../../layout/Empty";
import './Schedules.scss';

const Schedules = () => {

  const {loading, schedules} = useContext(DashboardContext);

  const [days, setDays] = useState([]);

  useEffect(() => {
    let daysMapping = [],
      days = [],
      nextBookingDate = dayjs().add(gym.bookInAdvanceHours, 'hours'),
      orderedDays = [];

    do {
      orderedDays.push(nextBookingDate);
      nextBookingDate = nextBookingDate.add(1, 'day');
    } while (orderedDays.length < 7);

    schedules.forEach(schedule => {
      daysMapping[schedule.day] = daysMapping[schedule.day] || [];
      daysMapping[schedule.day].push(schedule);
    });

    orderedDays.forEach(d => {
      days.push({
        day: d,
        schedules: daysMapping[d.day()] || []
      });
    });
    setDays(days);

  }, [schedules]);

  const handleScheduleRemove = (schedule) => {
    // find the schedule and kill it
    days.forEach(day => {
      const idx = day.schedules.findIndex(c => c.id === schedule.id);
      if (idx >= 0) {
        day.schedules.splice(idx, 1);
      }
    });
    // delay for it to wait for it to finish delete
    setTimeout(() => {
      setDays([...days]);
    }, 100);
  };

  const handleSchedule = (schedule, once, suspend, suspendOnce) => {
    // find the schedule and kill it
    days.forEach(day => {
      const existing = day.schedules.find(c => c === schedule);
      if (existing) {
        existing.once = once;
        existing.suspend = suspend;
        existing.suspend_once = suspendOnce;
      }
    });
    setDays([...days]);
  }

  return <div className={"schedules" + (loading ? ' loading' : '')}>
    {days.map(day =>
      <Day key={day.day.day()} title={day.day.format('dddd')}>
        {day.schedules.length > 0 ?
          day.schedules.map(schedule => <Class
            key={schedule.id}
            schedule={schedule}
            name={schedule.class_name}
            staffName={schedule.staff_name}
            clubName={schedule.club_name}
            startTime={dayjs(day.day.format('YYYY-MM-DD ') + schedule.start_time)}
            endTime={dayjs(day.day.format('YYYY-MM-DD ') + schedule.end_time)}
            onRemoveSchedule={() => handleScheduleRemove(schedule)}
            onSchedule={(id, once, suspend, suspendOnce) => handleSchedule(schedule, once, suspend, suspendOnce)}
            once={schedule.once}
            suspend={schedule.suspend}
            suspendOnce={schedule.suspend_once}
            scheduleId={schedule.id}
            showRemoveScheduleConfirmation={true}
            classMissing={!schedule.has_class}
          />) : <Empty>Nothing scheduled for this day</Empty>}
      </Day>)}
  </div>;
};

export default Schedules;