import './BottomNavigation.scss';

const BottomNavigation = ({
  tabs, onTab, activeTab
}) => {

  const handleTab = tab => {
    onTab(tab);
  };

  return <div className={"bottom-navigation"}>
    {tabs.map(tab => <button
      key={tab.name}
      className={"button" + (activeTab === tab.name ? ' active' : '')}
      onClick={e => handleTab(tab.name)}
    >
      <div className={"icon material-icons"}>{tab.icon}</div>
      <div className={"lbl"}>{tab.label}</div>
    </button>)}
  </div>
};

export default BottomNavigation;