import './AccountSuspended.scss';
import {useContext} from "react";
import {ConfirmContext, UserContext} from "../Contexts";
import {unsuspendSchedule} from "../api/user";

const AccountSuspended = () => {

  const {setUser} = useContext(UserContext);
  const {show: showConfirm} = useContext(ConfirmContext);

  const handleUnsuspend = () => {
    showConfirm({
      title: `Resume Booking?`,
      question: <>
        <p>Do you want to resume booking now?</p>
      </>,
      confirmBtnText: 'Resume Booking',
      confirmBtnClass: '',
      cancelBtnText: 'Cancel',
      cancelBtnClass: 'btn-text',
      onConfirm: () => {
        unsuspendSchedule().then(res => {
          setUser(res.data);
        }).catch(() => {
          alert('Failed to resume booking');
        })
        return true;
      }
    });
  }
  return <div className={"AccountSuspended"} onClick={handleUnsuspend}>
    Account suspended. Tap here to unsuspend.
  </div>
};

export default AccountSuspended;