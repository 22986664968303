import Tab from "../layout/Tab";
import './SearchTab.scss';
import SearchAppBar from "./search/SearchAppBar";
import {useContext, useEffect, useRef, useState} from "react";
import {getClasses, getSearchOptions} from "../../api/gym";
import SearchCandidates from "./search/SearchCandidates";
import SearchResult from "./search/SearchResult";
import TimePicker from "./search/TimePicker";
import Fuse from "fuse.js";
import useDebounce from "../../useDebounce";
import {DashboardContext} from "../../Contexts";

const SearchTab = props => {

  const searchAppBarRef = useRef();
  const tabRef = useRef();
  const {active} = props;

  const {onRefresh: refreshDashboard} = useContext(DashboardContext);
  const [showTimePicker, setShowTimePicker] = useState(false);

  const [allFilters, setAllFilters] = useState([]);

  const [search, setSearch] = useState('');
  const [allMode, setAllMode] = useState(false);
  const [candidates, setCandidates] = useState([]);
  const [filters, setFilters] = useState([]);
  const [classes, setClasses] = useState([]);

  const [searchPager, setSearchPager] = useState(null);
  const [page, setPage] = useState(1);
  const [endOfPage, setEndOfPage] = useState(false);
  const [searching, setSearching] = useState(false);
  const [loading, setLoading] = useState(false);

  const searchTerm = useDebounce(search, 500);


  useEffect(() => {
    // when searching changes, it affects loading too.
    // but if loading changes, it doesn't affect searching.
    setLoading(searching);
  }, [searching]);

  useEffect(() => {
    const handleScroll = () => {
      if ((window.innerHeight + window.scrollY + 5) >= document.body.offsetHeight) {
        const inView = window.getComputedStyle(tabRef.current, null).display === 'block';
        if (inView && !loading && !endOfPage && !search) {
          setPage(page + 1);
        }
      }
    }
    if (active) {
      window.addEventListener('scroll', handleScroll);
    }
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [active, loading, endOfPage, search, setPage, page]);

  useEffect(() => {
    getSearchOptions().then(res => {
      let allFilters = [];
      allFilters = allFilters.concat(res.data.clubs.map(u => ({...u, ...{type: 'club'}})));
      allFilters = allFilters.concat(res.data.instructors.map(u => ({...u, ...{type: 'instructor'}})));
      res.data.topics.forEach(topic => {
        allFilters = allFilters.concat(topic.sub_topics.map(u => ({...u, ...{type: 'subtopic'}})));
      });
      setAllFilters(allFilters);
    }).catch(res => {
      alert('Cannot load search options. Please reload this page');
    });
  }, []);

  useEffect(() => {
    setPage(1);
    if (filters.length) {
      const query = {};
      const subTopicIds = [];
      filters.forEach(filter => {
        switch(filter.type) {
          case 'club': query.club_id = filter.id; break;
          case 'instructor': query.instructor_id = filter.id; break;
          case 'subtopic': subTopicIds.push(filter.id); break;
          case 'time': query.from_time = filter.id.unix(); break;
          default: break;
        }
      });
      if (subTopicIds.length > 0) {
        query.sub_topic_ids = subTopicIds.join(',');
      }
      setEndOfPage(false);
      setSearching(true);
      setSearchPager(getClasses(query));

      // set content padding top to the height
      tabRef.current.style.paddingTop = searchAppBarRef.current.getHeight() + 'px';
    } else {
      tabRef.current.style.paddingTop = null;
      setSearchPager(null);
    }
  }, [filters]);

  useEffect(() => {
    if (searchPager && !loading) {
      setLoading(true);
      searchPager.setPage(page).get().then(res => {
        // if it's page 1 it's always a replacement
        if (page === 1) {
          window.scrollTo(0, 0);
          setClasses(res.data);
        }
        else setClasses(c => [...c, ...res.data]);

        setEndOfPage(res.data.length === 0);
      }).catch(err => {
        alert(err.response?.data?.message || 'Cannot search classes');
      }).finally(() => {
        setLoading(false);
        setSearching(false); // always stop searching too when it's done
      });
    }
  }, [searchPager, page]);

  useEffect(() => {

    // ignored if it's all mode
    if (allMode) return;

    if (searchTerm.length > 0) {
      setSearching(true);

      const clubSelected = !!filters.find(c => c.type === 'club');
      const instructorSelected = !!filters.find(c => c.type === 'instructor');

      // First, we filter through the only possible filters:
      const available = allFilters.filter(u =>
        (!clubSelected || u.type !== 'club') &&
        (!instructorSelected || u.type !== 'instructor') &&
        filters.indexOf(u) < 0
      );

      // Then we use fuse to search
      // https://fusejs.io/api/options.html
      const fuse = new Fuse(available, {
        includeScore: true,
        threshold: 0.2,
        keys: ['name']
      });

      const result = fuse.search(searchTerm);
      setCandidates(result.map(r => r.item));
    } else {
      setCandidates([]);
    }
    setSearching(false);
  }, [searchTerm]);

  const handleSearchBarValue = value => {
    setSearch(value);
    if (value.length > 0) {
    } else {
      // reset all mode when it's cleared
      setAllMode(false);
      setCandidates([]);
    }
  };

  const handleCandidate = candidate => {
    if (!loading) {
      const exist = filters.find(f => f === candidate);
      if (!exist) {
        setFilters([...filters, ...[candidate]]);
      }
      setSearch('');
      // setCandidates([]);
    }
  }

  const handleRemoveFilter = filter => {
    if (!loading) {
      const idx = filters.indexOf(filter);
      filters.splice(idx, 1);
      setFilters([...filters]);

      // on removing the filters while the user is in search, we attempt to put back the filter into the
      // candidate list
      if (search) {
        // research
        handleSearchBarValue(search);
      }
    }
  }

  const handleShowAllClubs = () => {
    setSearch('All Clubs');
    setAllMode(true);
    setCandidates(allFilters.filter(u => u.type === 'club'));
  }

  const handleShowAllClassTypes = () => {
    setSearch('All Class Types');
    setAllMode(true);
    setCandidates(allFilters.filter(u => u.type === 'subtopic').sort((a, b) => a.name > b.name ? 1 : -1));
  }

  const handleShowAllInstructors = () => {
    setSearch('All Instructors');
    setAllMode(true);
    setCandidates(allFilters.filter(u => u.type === 'instructor'));
  }

  const handleRefresh = () => {
    if (searchPager && !loading) {
      setPage(1);
      setEndOfPage(false);
      setSearchPager(searchPager.clone());
    }
  }

  const handleTimePicker = () => {
    if (!loading) setShowTimePicker(true);
  }

  const handleTimePickerClose = () => {
    setShowTimePicker(false);
  };

  const handleTimePickerPick = d => {
    // always remove the time filter
    const timeFilter = filters.find(f => f.type === 'time');
    if (timeFilter) {
      filters.splice(filters.indexOf(timeFilter), 1);
    }
    setFilters([...filters, ...[{
      type: 'time',
      id: d,
      name: d.format('ddd, D MMM, h:mmA')
    }]]);
  };

  const handleClassChange = () => {
    refreshDashboard();
  }

  return <Tab {...props} ref={tabRef} className={"SearchTab"} appBar={<SearchAppBar
    onRefresh={handleRefresh}
    onTimePicker={handleTimePicker}
    loading={loading}
    allMode={allMode}
    value={search}
    onValue={handleSearchBarValue}
    filters={filters}
    onRemoveFilter={handleRemoveFilter}
    ref={searchAppBarRef}
  />}>
    {showTimePicker && <TimePicker onClose={handleTimePickerClose} onPick={handleTimePickerPick} />}
    {searching || (search !== searchTerm) ? <div className={"search-instruction"}>Searching...</div> : <>
      {searchTerm ? <>
        {candidates.length > 0 ? <SearchCandidates candidates={candidates} onCandidate={handleCandidate}/> : <div className={"search-instruction"}>
          <p>Nothing is found. Widen your search by using less keywords?</p>
          <p>Also take note that there can only be 1 instructor and 1 club, but multiple class types.</p>
        </div>}
      </> : <>
        {filters.length > 0 ? (classes.length > 0 ? <SearchResult loading={loading} classes={classes} onChange={handleClassChange} /> : <div className={"search-instruction"}>
          Nothing is found. Widen your search by remove some filters?
        </div>) : <div className={"search-instruction"}>
          <p>Search anything in the box above, then filter it through to find your favourite classes ;)</p>
          <p>Alternatively, pick one from below:</p>
          <p>Select from: <button className={"btn-text"} onClick={handleShowAllClubs}>Clubs</button> /&nbsp;
            <button className={"btn-text"} onClick={handleShowAllClassTypes}>Class Types</button> /&nbsp;
            <button className={"btn-text"} onClick={handleShowAllInstructors}>Instructors</button>
          </p>
        </div>}
      </>}
    </>}
  </Tab>
}

export default SearchTab;
