import React from 'react';
import {defaultColor} from "./config";

export const ThemeContext = React.createContext({
  theme: 'dark', setTheme: () => {}, onTheme: () => {},
  color: defaultColor, setColor: () => {}, onColor: () => {}
});

export const UserContext = React.createContext({
  user: null, setUser: () => {}, onLogout: () => {}
});

export const ConfirmContext = React.createContext({
  show: () => {},
});

export const DashboardContext = React.createContext({
  initialised: false,
  loading: false,
  onRefresh: () => {},
  schedules: [],
  upcomingClasses: [],
  waitingClasses: []
});