import './ColorPicker.scss';
import {colors} from '../../../config';
import {useContext} from "react";
import {ThemeContext} from "../../../Contexts";


const ColorPicker = () => {

  const {color, onColor} = useContext(ThemeContext);
  const handlePicker = color => {
    onColor(color);
  }

  return <div className={"ColorPicker"}>
     {colors.map(c => <div key={c}
                           onClick={e => handlePicker(c)}
                           className={"picker" + (color === c ? ' active' : '')}
                           style={{backgroundColor: c}}/>)}
   </div>
};

export default ColorPicker;