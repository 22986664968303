import {post} from './api';

export function loginUser(username, password) {
  return post('/auth/login', {
    username, password
  });
}

export function refresh() {
  return post('/auth/refresh');
}

export function updatePassword(password) {
  return post('/auth/update-password', {password});
}