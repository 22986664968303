import Button from "./Button";
import './BookButton.scss';
import {bookClass, cancelClass, joinWaitlist, leaveWaitlist} from "../../api/gym";
import {useContext} from "react";
import {ConfirmContext} from "../../Contexts";


const BookButton = ({cls, loading, onChange, onLoading}) => {

  const {show: showConfirm} = useContext(ConfirmContext);
  let lbl;
  if (cls.waitlisted) {
    lbl = `Leave Waitlist (${cls.waitlist_position})`;
  } else if (cls.booked) {
    lbl = 'Cancel Class';
  } else if (cls.full) {
    lbl = 'Join Waitlist';
  } else {
    lbl = 'Book Class';
  }

  const handleBookClass = () => {
    if (!loading) {
      onLoading(true);
      bookClass(cls.id).then(res => {
        onChange(res.data);
      }).catch(res => {
        setTimeout(() => {
          alert(res.response?.data?.message || 'Unexpected error');
        }, 50);
      }).finally(() => {
        onLoading(false);
      });
    }
  };

  const handleCancelClass = () => {
    if (!loading) {
      showConfirm({
        title: `Cancel ${cls.name}?`,
        question: <>
          <p>You are about to cancel <strong>{cls.name}</strong> by <strong>{cls.instructors.map(m => m.name).join(', ')}</strong>.</p>
          <p>This will release you from your slot and you may not be able to book it back later.</p>
        </>,
        confirmBtnText: 'Cancel Class',
        confirmBtnClass: 'btn-danger',
        cancelBtnText: 'Go Back',
        doubleConfirmText: 'I understand the risk',
        onConfirm: () => {
          onLoading(true);
          cancelClass(cls.booking_id, cls.id).then(res => {
            onChange(res.data);
          }).catch(res => {
            setTimeout(() => {
              alert(res.response?.data?.message || 'Unexpected error');
            }, 50);
          }).finally(() => {
            onLoading(false);
          });
        }
      });
    }
  };

  const handleJoinWaitlist = () => {
    if (!loading) {
      onLoading(true);
      joinWaitlist(cls.id).then(res => {
        onChange(res.data);
      }).catch(res => {
        setTimeout(() => {
          alert(res.response?.data?.message || 'Unexpected error');
        }, 50);
      }).finally(() => {
        onLoading(false);
      });
    }
  };

  const handleLeaveWaitlist = () => {
    if (!loading) {
      showConfirm({
        title: `Leave Waitlist?`,
        question: <>
          <p>You are about to leave the waiting list for <strong>{cls.name}</strong> by <strong>{cls.instructors.map(m => m.name).join(', ')}</strong>.</p>
          <p>This will reset your position ({cls.waitlist_position} of {cls.waitlist_count}) and put you back to the end of the waiting line should you join the queue again at a later time.</p>
        </>,
        confirmBtnText: 'Leave Waitlist',
        confirmBtnClass: 'btn-danger',
        cancelBtnText: 'Go Back',
        doubleConfirmText: 'I understand the risk',
        onConfirm: () => {
          onLoading(true);
          leaveWaitlist(cls.waitlist_id, cls.id).then(res => {
            onChange(res.data);
          }).catch(res => {
            setTimeout(() => {
              alert(res.response?.data?.message || 'Unexpected error');
            }, 50);
          }).finally(() => {
            onLoading(false);
          });
        }
      });
    }
  };

  const handleClick = () => {
    // 4 types of actions:
    // 1. Have book_id : Cancel
    // 2. Have waitlist_id : Leave waitlist
    // 3. Full: Join Waitlist
    // 4. Not full: Book class
    if (cls.booking_id) {
      handleCancelClass();
    } else if (cls.waitlist_id) {
      handleLeaveWaitlist();
    } else if (cls.full) {
      handleJoinWaitlist();
    } else {
      handleBookClass();
    }
  };

  return <Button className={"BookButton" +
    (cls.booked ? ' booked' : (
      cls.waitlisted || cls.full ? ' waitlist' : ''
    ))
  } onClick={handleClick}>
    {lbl}
  </Button>;
};

export default BookButton;