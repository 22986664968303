import './SearchCandidate.scss';
const SearchCandidate = ({candidate, onCandidate}) => {

  let icon = 'travel_explore';
  switch(candidate.type) {
    case 'instructor': icon = 'face'; break;
    case 'club': icon = 'fitness_center'; break;
    case 'subtopic': icon = 'sports_gymnastics'; break;
    default: break;
  }

  const handleClick = () => {
    onCandidate(candidate);
  };

  return <div className={"SearchCandidate"} onClick={handleClick}>
    <div className={"icon"}>
      <span className={"material-icons"}>{icon}</span>
    </div>
    <div className={"label"}>
      {candidate.name}
    </div>
  </div>
};

export default SearchCandidate;