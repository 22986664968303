import {useContext, useEffect, useState} from "react";
import Class from "../../shared/Class";
import {DashboardContext} from '../../../Contexts';
import dayjs from "dayjs";
import Day from "../../layout/Day";
import Empty from "../../layout/Empty";
import Loader from "../../layout/Loader";
import './UpcomingClasses.scss';

const UpcomingClasses = () => {

  const {loading, initialised, upcomingClasses, waitingClasses} = useContext(DashboardContext);

  const [days, setDays] = useState([]);

  useEffect(() => {
    // regrouping classes
    const classes = upcomingClasses.concat(waitingClasses);
    classes.sort((a, b) => {
      if (a.start_time === b.start_time) {return 0;}
      return a.start_time > b.start_time ? 1 : -1;
    });
    const mapping = [];
    const days = [];
    classes.forEach(cls => {
      const startTime = dayjs(cls.start_time);
      const date = startTime.format('YYYY-MM-DD');
      mapping[date] = mapping[date] || [];
      mapping[date].push(cls);
    });
    const sortedKeys = Object.keys(mapping).sort();
    sortedKeys.forEach(key => {
      const obj = dayjs(key, 'YYYY-MM-DD');
      return days.push({
        classes: mapping[key],
        date: key,
        obj,
        day: obj.day()
      })
    });
    setDays(days);
  }, [upcomingClasses, waitingClasses]);

  const handleClassChange = cls => {
    // find the class and update it and rerender
    let replaced = false;
    days.forEach(day => {
      const idx = day.classes.findIndex(c => c.id === cls.id);
      if (idx >= 0) {
        day.classes.splice(idx, 1, cls);
        replaced = true;
      }
    });
    if (replaced) {
      setDays([...days]);
    }
  };

  const today = dayjs();

  return <div className={"upcoming-classes" + (loading ? ' loading' : '')}>
    {initialised ? <>
      {days.length > 0 ? <>
        {days.map(day => <Day key={day.date} title={day.date === today.format('YYYY-MM-DD') ? `Today - ${day.obj.format('dddd')}` : (
          day.date === today.clone().add(1, 'day').format('YYYY-MM-DD') ? `Tomorrow - ${day.obj.format('dddd')}` : day.obj.format('dddd')
        )}>
          {day.classes.map(cls => <Class
            onChange={handleClassChange}
            key={cls.id}
            cls={cls}
            name={cls.name}
            clubId={cls.club_id}
            staffName={cls.instructors.map(i => i.name).join(', ')}
            clubName={cls.club_name}
            startTime={dayjs(cls.start_time)}
            endTime={dayjs(cls.end_time)}
            classMissing={false}
            showQuota={true}
            remaining={cls.leftover - cls.waitlist_count}
            capacity={cls.capacity}
            showSchedule={false}
            attended={cls.attended}
          />)}
        </Day>)}
      </> : <Empty>No confirmed/waitlisted classes</Empty>}
    </> : <Loader />}
  </div>
};

export default UpcomingClasses;