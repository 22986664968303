import {get, post, put, del} from './api';
// import moment from 'moment';

export function getSchedules() {
  return get('/schedules');
}

/**
 * Create schedule from clazz object or visit object. They both have the information
 * required to create schedule
 * @param o
 * @param once
 * @returns {Promise<any>}
 */
export function createSchedule(o, once = 0) {
  // let data = {
  //   location_id: o.location_id,
  //   day: moment(o.class_date, 'YYYY-MM-DD').day(),
  //   location_name: o.location_name,
  //   class_name: o.class_name,
  //   start_time: o.start_time,
  //   end_time: o.end_time,
  //   once
  // };

  return post('/schedules', {...o, ...{once}});
}

export function updateSchedule(scheduleId, data) {
  return put('/schedules/' + scheduleId, data);
}

export function deleteSchedule(scheduleId) {
  return del('/schedules/' + scheduleId);
}