import Button from "./Button";
import './ScheduleButton.scss';
import {useContext, useEffect, useRef, useState} from "react";
import {ConfirmContext} from "../../Contexts";
import {createSchedule, deleteSchedule, updateSchedule} from "../../api/schedule";

const ScheduleButton = ({
  loading = false, onLoading = () => {},
  clubId, day, clubName, className,
  startTime, endTime,

  once: propsOnce = false, suspend: propsSuspend = false,
  suspendOnce: propsSuspendOnce = false, scheduleId: propsScheduleId = null,

  onSchedule = () => {}, onRemoveSchedule = () => {}, showRemoveConfirmation = false
}) => {


  const toggleBtnRef = useRef();
  const dropdownRef = useRef();
  const {show: showConfirm} = useContext(ConfirmContext);

  const [showDropdown, setShowDropdown] = useState(false);
  const [dropdownOverflowed, setDropdownOverflowed] = useState(false);

  const [opening, setOpening] = useState(false);
  const [closing, setClosing] = useState(false);

  const [once, setOnce] = useState(propsOnce);
  const [suspend, setSuspend] = useState(propsSuspend);
  const [suspendOnce, setSuspendOnce] = useState(propsSuspendOnce);
  const [scheduleId, setScheduleId] = useState(propsScheduleId);

  const bookWeekly = scheduleId && !once && !suspend;
  const bookThisWeekOnly = scheduleId && once && !suspend;
  const skipThisWeekOnly = scheduleId && suspend && suspendOnce;
  const suspended = scheduleId && suspend && !suspendOnce;

  const booking = bookWeekly || bookThisWeekOnly;
  const suspending = suspended || skipThisWeekOnly;


  const showDD = () => {
    setShowDropdown(true);
    setOpening(true);
    setTimeout(() => {
      setOpening(false);
    }, 100);
  };

  const hideDD = () => {
    setClosing(true);
    setTimeout(() => {
      setClosing(false);
      setShowDropdown(false);
    }, 100);
  };

  useEffect(() => {
    setOnce(propsOnce);
    setSuspend(propsSuspend);
    setSuspendOnce(propsSuspendOnce);
    setScheduleId(propsScheduleId);
  }, [propsOnce, propsSuspend, propsSuspendOnce, propsScheduleId]);

  useEffect(() => {
    const handleClick = (e) => {
      if (dropdownRef.current && toggleBtnRef.current) {
        if (!dropdownRef.current.contains(e.target) &&
          !toggleBtnRef.current.contains(e.target)) {
          hideDD();
        }
      }
    };
    document.addEventListener('mousedown', handleClick, false);
    return () => {
      document.removeEventListener('mousedown', handleClick, false);
    }
  }, []);

  const handleAddSchedule = () => {
    if (!loading && !scheduleId) {
      onLoading(true);
      createSchedule({
        club_id: clubId,
        day,
        club_name: clubName,
        class_name: className,
        start_time: startTime,
        end_time: endTime
      }).then(res => {
        setScheduleId(res.data.id);
        setOnce(false);
        setSuspend(false);
        setSuspendOnce(false);
        onSchedule(res.data.id, false, false, false);
      }).catch(err => {
        if (err.response.data && err.response.data.conflicting_schedule) {
          alert(err.response?.data?.message);
          // conflictingScheduleContext.onConflict(err.response.data.conflicting_schedule, cls, false, (conflictingSchedule, clz, mode) => {
          //   console.log(conflictingSchedule, clz, mode);
          // });
        } else {
          // non blocking
          setTimeout(() => {
            alert(err.response?.data?.message);
          }, 50);
        }
      }).finally(() => {
        onLoading(false);
      });
    }
  };

  const handleRemoveSchedule = () => {
    if (!loading && scheduleId) {
      if (showRemoveConfirmation) {
        showConfirm({
          title: `Delete ${className}?`,
          question: <>
            <p>You are about to delete <strong>{className}</strong> at <strong>{clubName}</strong>.</p>
            <p>Deleting a schedule will only stop us from booking this class for you. It does not affect your currently enrolled classes on Class Booker.</p>
          </>,
          confirmBtnText: 'Delete Schedule',
          confirmBtnClass: 'btn-danger',
          cancelBtnText: 'Go Back',
          onConfirm: () => {
            onLoading(true);
            return deleteSchedule(scheduleId).then(res => {
              setScheduleId(null);
              hideDD();
              onRemoveSchedule();
              return true;
            }).catch(err => {
              // non blocking
              setTimeout(() => {
                alert(err.response?.data?.message);
              }, 50);
              onLoading(false);
              return false;
            }).finally(() => {
              onLoading(false);
            });
          }
        });
      } else {
        onLoading(true);
        deleteSchedule(scheduleId).then(res => {
          setScheduleId(null);
          hideDD();
          onRemoveSchedule();
        }).catch(err => {
          // non blocking
          setTimeout(() => {
            alert(err.response?.data?.message);
          }, 50);
        }).finally(() => {
          onLoading(false);
        });
      }
    }
  };


  const toggleDropdown = e => {
    if (!showDropdown) {
      showDD();
    } else {
      hideDD();
    }
    if (e.target.getBoundingClientRect) {
      const top = e.target.getBoundingClientRect().top;
      const vh = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
      setDropdownOverflowed(top > vh - 280);
    }
  };



  const handleBookWeekly = () => updateStatus(false, false, false);
  const handleBookThisWeekOnly = () => updateStatus(true, false, false);
  const handleSkipThisWeekOnly = () => updateStatus(false, true, true);
  const handleSuspend = () => updateStatus(false, true, false);

  /**
   * @param {boolean} o Once
   * @param {boolean} s Suspend
   * @param {boolean} so Suspend Once
   */
  const updateStatus = (o, s, so) => {
    if (!loading && scheduleId) {
      hideDD();
      onLoading(true);
      updateSchedule(scheduleId, {
        once: o, suspend: s, suspend_once: so
      }).then(res => {
        setOnce(o);
        setSuspend(s);
        setSuspendOnce(so);
        onSchedule(scheduleId, o, s, so);
      }).catch(err => {
        console.dir(err);
        if (err.response.data && err.response.data.conflicting_schedule) {
          alert(err.response?.data?.message);
          // conflictingScheduleContext.onConflict(err.response.data.conflicting_schedule, cls, once, (conflictingSchedule, clz, mode) => {
          //   console.log(conflictingSchedule, clz, mode);
          // });
        } else {
          // non blocking
          setTimeout(() => {
            alert(err.response?.data?.message);
          }, 50);
        }
      }).finally(() => {
        onLoading(false);
      });
    }
  };

  return <div className={"ScheduleButton" +
    (showDropdown ? ' show-dropdown' : '') +
    (opening ? ' opening' : '') +
    (closing ? ' closing' : '') +
    (dropdownOverflowed ? ' dropdown-overflowed' : '')}>
    {scheduleId ? <Button
        className={
          "btn-toggle" +
          (booking ? ' booking' : '') +
          (suspending ? ' suspending' : '')
        }
        ref={toggleBtnRef}
        onClick={toggleDropdown}
    >
        <span className={"lbl"}>
          {bookWeekly && 'Book Weekly'}
          {bookThisWeekOnly && 'Book This Week Only'}
          {skipThisWeekOnly && 'Skip This Week Only'}
          {suspended && 'Suspended'}
        </span>
        <span className={"icon material-icons"}>arrow_drop_down</span>
      </Button> : <Button className={"btn-add"} onClick={handleAddSchedule}>
      <span className={"lbl"}>Add Schedule</span>
    </Button>}

    {showDropdown && <div className={"dd"} ref={dropdownRef}>

      <div className={"dd-item" + (bookWeekly ? ' active' : '')}>
        <button className={"btn"} onClick={handleBookWeekly}>
          <span className={"lbl"}>Book Weekly</span>
        </button>
      </div>
      <div className={"dd-item" + (bookThisWeekOnly ? ' active' : '')}>
        <button className={"btn"} onClick={handleBookThisWeekOnly}>
          <span className={"lbl"}>Book This Week Only</span>
        </button>
      </div>
      <div className={"dd-item"+ (skipThisWeekOnly ? ' active' : '')}>
        <button className={"btn"} onClick={handleSkipThisWeekOnly}>
          <span className={"lbl"}>Skip This Week Only</span>
        </button>
      </div>
      <div className={"dd-separator"} />
      <div className={"dd-item"+ (suspended ? ' active' : '')}>
        <button className={"btn"} onClick={handleSuspend}>
          <span className={"lbl"}>Suspend</span>
        </button>
      </div>
      <div className={"dd-separator"} />
      <div className={"dd-item delete"} onClick={handleRemoveSchedule}>
        <button className={"btn"}>
          <span className={"lbl"}>Delete Schedule</span>
        </button>
      </div>
    </div>}
  </div>;
};

export default ScheduleButton;