import React, {useRef, useState} from 'react';
import Prompt from "./Prompt";
import './Confirm.scss';

const Confirm = props => {

  const {
    className = '',
    title = 'Confirm',
    question,
    confirmBtnText = 'Confirm',
    confirmBtnClass = 'btn-primary',
    cancelBtnText = 'Cancel',
    cancelBtnClass = 'btn-secondary',
    doubleConfirmText = null,
    onConfirm = () => {},
    onCancel = () => {},
    onClose = () => {}
  } = props;

  const promptRef = useRef();
  const [loading, setLoading] = useState(false);
  const [doubleConfirmed, setDoubleConfirmed] = useState(false);

  const handleCancel = () => {
    if (onCancel() !== false) {
      promptRef.current.close();
    }
  };

  const handleConfirm = () => {
    const ret = onConfirm();
    if (ret) {
      if (ret.then) {
        setLoading(true);
        ret.then(res => {
          setLoading(false);
          if (res !== false) {
            promptRef.current.close();
          }
        }).catch(() => {
          setLoading(false);
        });
      } else if (ret !== false) {
        promptRef.current.close();
      }
    } else {
      if (ret !== false) {
        promptRef.current.close();
      }
    }
  };

  const handleDoubleConfirmChange = e => {
    setDoubleConfirmed(e.target.checked);
  };

  const checkboxId = 'confirm-' + (new Date()).getTime();

  return <Prompt
    ref={promptRef}
    className={className + " Confirm" + (loading ? ' loading' : '')}
    title={title}
    onClose={onClose}
  >
    <div className={"question"}>{question}</div>
    {doubleConfirmText && <div className={"double-confirm" + (doubleConfirmed ? ' confirmed' : '')}>
      <div className="form-check">
        <input className="form-check-input" type="checkbox" value="" id={checkboxId} onChange={handleDoubleConfirmChange} checked={doubleConfirmed} />
        <label className="form-check-label" htmlFor={checkboxId}>
        {doubleConfirmText}
        </label>
      </div>
    </div>}
    <div className={"actions"}>
      <button className={"btn btn-cancel " + cancelBtnClass} onClick={handleCancel}>{cancelBtnText}</button>
      <button className={"btn btn-confirm " + confirmBtnClass} onClick={handleConfirm} disabled={doubleConfirmText != null && !doubleConfirmed}>{confirmBtnText}</button>
    </div>
  </Prompt>;
};

export default Confirm;