import {get, paged, post} from './api';
// import {CLASSES} from "../gym-classes";

export function getSearchOptions() {
  return get('/classes/search-options');
}

export function getClubs() {
  return get('/gyms/clubs?no_pagination');
}

export function getClasses(query = {}) {
  return paged('/classes', query);
}

export function getClassesForThisWeek(locationId) {
  return get(`/gyms/locations/${locationId}/classes-for-this-week`);
  // return new Promise(resolve => {
  //   setTimeout(() => {
  //     resolve({
  //       data: CLASSES.data.map(o => ({...o}))
  //     });
  //   }, 300);
  // })
}

export function bookClass(classId) {
  return post(`/classes/${classId}/book`);
}

export function cancelClass(bookingId, classId) {
  return post(`/bookings/${bookingId}/cancel`, {class_id: classId});
}

export function joinWaitlist(classId) {
  return post(`/classes/${classId}/join-waitlist`);
}

export function leaveWaitlist(waitlistId, classId) {
  return post(`/waitlist/${waitlistId}/leave`, {class_id: classId});
}

export function getUpcomingClasses() {
  // return new Promise((resolve, reject) => {
  //   resolve({
  //     data: [{
  //       "id": 3036305,
  //       "name": "Gym Floor",
  //       "start_time": "2021-12-31T07:00:00+08:00",
  //       "end_time": "2021-12-31T08:30:00+08:00",
  //       "capacity": 16,
  //       "leftover": 0,
  //       "booking_id": 17215439,
  //       "booking_position": 1,
  //       "waitlist_id": null,
  //       "waitlist_count": 0,
  //       "waitlist_position": null,
  //       "booked": true,
  //       "waitlisted": false,
  //       "full": false,
  //       "available": true,
  //       "club_id": 133,
  //       "club_name": "Paragon",
  //       "schedule_id": null,
  //       "schedule_once": false,
  //       "schedule_suspend": false,
  //       "schedule_suspend_once": false,
  //       "instructors": [{"id": 6038, "name": "Paragon..."}]
  //     }]
  //   });
  // });
  return get('/classes/upcoming-classes');
}

export function getWaitlistedClasses() {
  // return new Promise((resolve, reject) => {
  //   resolve({
  //     data: [{
  //       "id": 3032223,
  //       "name": "BODYJAM\u00ae",
  //       "start_time": "2021-12-30T19:45:00+08:00",
  //       "end_time": "2021-12-30T20:45:00+08:00",
  //       "capacity": 24,
  //       "leftover": 0,
  //       "booking_id": null,
  //       "booking_position": null,
  //       "waitlist_id": 2744508,
  //       "waitlist_count": 21,
  //       "waitlist_position": 20,
  //       "booked": false,
  //       "waitlisted": true,
  //       "full": true,
  //       "available": false,
  //       "club_id": 134,
  //       "club_name": "One George Street",
  //       "schedule_id": "bc5714b4-8718-4a72-8a75-673a2ca33d8a",
  //       "schedule_once": false,
  //       "schedule_suspend": false,
  //       "schedule_suspend_once": false,
  //       "instructors": [{"id": 5420, "name": "Ben Ning"}]
  //     }]
  //   });
  // })
  return get('/classes/waitlist-classes');
}

export function getPastClasses() {
  return get('/gyms/past-classes');
}

export function getCancelledClasses() {
  return get('/gyms/cancelled-classes');
}
