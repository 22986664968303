import Confirm from "../../layout/Confirm";
import './ChangePassword.scss';
import {useState} from "react";
import {updatePassword} from "../../../api/auth";

const ChangePassword = (props) => {

  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');

  const handleChange = () => {
    if (password && !loading) {
      setLoading(true);
      return updatePassword(password).then(res => {
        setTimeout(() => {
          alert('Password changed');
        }, 50);
        return true;
      }).catch(err => {
        setTimeout(() => {
          alert(err.response?.data?.message || 'Failed to change password');
        }, 50);
        throw err;
      }).finally(() => {
        setLoading(false);
      });
    } else {
      return false;
    }
  };

  return <Confirm
    {...props}
    className={"ChangePassword"}
    title={"Change Password"}
    question={<>
      <p>If you have changed your password from within FF app, do change it here
        too so that we can continue to book classes for you:</p>
      <p>Note that this action does not change your FF account password. This action
        is merely update your password in our system:</p>
      <div className={"form-group"}>
        <label>New Password:</label>
        <input type={"password"} value={password} onChange={e => setPassword(e.target.value)} disabled={loading} />
      </div>
    </>}
    confirmBtnText={"Change Password"}
    onConfirm={handleChange}
  />;
}

export default ChangePassword;