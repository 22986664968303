import './App.scss';
import Login from "./Login";
import {UserContext, ThemeContext, ConfirmContext} from "./Contexts"
import {useEffect, useState} from "react";
import Dashboard from "./Dashboard";
import {apiLogout} from "./api/api";
import {me} from "./api/user";
import Confirm from "./components/layout/Confirm";
import {defaultColor} from "./config";
import Color from 'color';

const defaultDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
const systemTheme = defaultDark ? 'dark' : 'light';
let loadTheme = window.localStorage.getItem('cb-theme') ?? 'default';

if (['default', 'light', 'dark'].indexOf(loadTheme) < 0) {
  loadTheme = 'default';
}

function App() {

  const [theme, setTheme] = useState(loadTheme);
  const [color, setColor] = useState(window.localStorage.getItem('cb-color') ?? defaultColor);
  const [user, setUser] = useState(null);

  const [initialised, setInitialised] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmProps, setConfirmProps] = useState({});


  useEffect(() => {
    document.body.setAttribute('data-theme', theme === 'default' ? systemTheme : theme);

    const col = Color(color);
    const style = document.querySelector(':root').style;
    const dark = (theme === 'default' ? systemTheme : theme) === 'dark';

    style.setProperty('--accent', color);
    style.setProperty('--background', dark ? col.darken(0.9).desaturate(0.9) : 'white');
    style.setProperty('--text-primary', dark ? 'white' : col.darken(0.9));
    style.setProperty('--text-secondary', color);
    style.setProperty('--button', dark ? col.darken(0.7) : '#eee');
    style.setProperty('--app-bar', dark ? col.darken(0.8).desaturate(0.3) : color);
    style.setProperty('--bottom-navigation', dark ? col.darken(0.8).desaturate(0.3) : '#e5e5e5');
    style.setProperty('--class', dark ? col.darken(0.85).desaturate(0.7) : '#eee');
    style.setProperty('--day', dark ? col.darken(0.8).desaturate(0.7) : '#ddd');

    // Unrelated to accent
    style.setProperty('--class-waitlisted', dark ? (new Color('#403922')).mix(col, 0.1) : (new Color('#ffefbd')).mix(col, 0.1));
    style.setProperty('--class-booked', dark ? (new Color('#1d321e')).mix(col, 0.1) : (new Color('#d2ebb5')).mix(col, 0.1));
    style.setProperty('--class-suspended', dark ? (new Color('#332701')).mix(col, 0.1) : (new Color('#f6db87')).mix(col, 0.1));

  }, [theme, color]);

  const handleLogout = () => {
    if (window.confirm('Sign out now?')) {
      apiLogout();
      setUser(null);
    }
  };

  const handleConfirm = props => {
    setConfirmProps(props);
    setShowConfirm(true);
  }

  const handleTheme = t => {
    setTheme(t);
    window.localStorage.setItem('cb-theme', t);
  };

  const handleColor = c => {
    setColor(c);
    window.localStorage.setItem('cb-color', c);
  }

  const userValue = {user, setUser, onLogout: handleLogout};
  const themeValue = {theme, setTheme, onTheme: handleTheme, color, setColor, onColor: handleColor};
  const confirmValue = {show: handleConfirm};

  useEffect(() => {
    me().then(res => {
      setUser(res.data);
    }).finally(() => {
      setInitialised(true);
    });
  }, []);

  return <ThemeContext.Provider value={themeValue}>
    <ConfirmContext.Provider value={confirmValue}>
      <UserContext.Provider value={userValue}>
        <div className="App">
          {initialised ? <>
            {user ? <Dashboard/> : <Login/>}
            {showConfirm && <Confirm {...confirmProps} onClose={() => setShowConfirm(false)} />}
          </> : <div className={"app-loading"}>Loading...</div>}
        </div>
      </UserContext.Provider>
    </ConfirmContext.Provider>
  </ThemeContext.Provider>;
}

export default App;
