import './Login.scss';
import {useContext, useState} from "react";
import {UserContext} from "./Contexts";
import {loginUser} from "./api/auth";
import {setUserSessionToken} from "./api/api";
const Login = () => {

  const {setUser} = useContext(UserContext);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const signInDisabled = loading || username.trim() === '' || password.trim() === '';

  const handleUsername = e => {
    setError('');
    setUsername(e.target.value);
  };

  const handlePassword = e => {
    setError('');
    setPassword(e.target.value);
  }

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      handleSignIn();
    }
  }

  const handleSignIn = () => {
    if (!signInDisabled) {
      setLoading(true);
      loginUser(username, password).then(res => {
        if (res.data?.session?.access_token) {
          setUser(res.data);
          setUserSessionToken(res.data.session.access_token);
        } else {
          alert('Unexpected response returned');
        }
      }).catch(res => {
        setError(res.response?.data?.message || 'Unexpected error');
      }).finally(() => {
        setLoading(false);
      });
    }
  }

  return <div className={"login"}>
    <div className={"prompt"}>
      <div className={"logo"}>
        Let’s Book!
      </div>
      <div className={"error" + (error ? ' show' : '')}>
        {error || 'Error'}
      </div>
      <div className={"form"}>
        <div className={"form-group"}>
          <div className={"icon material-icons"}>email</div>
          <div className={"control"}>
            <input type={"email"} placeholder={"Email address"} value={username} onChange={handleUsername} onKeyDown={handleKeyDown} disabled={loading} />
          </div>
        </div>
        <div className={"form-group"}>
          <div className={"icon material-icons"}>vpn_key</div>
          <div className={"control"}>
            <input type={"password"} placeholder={"Password"} value={password} onChange={handlePassword} onKeyDown={handleKeyDown} disabled={loading} />
          </div>
        </div>
        <div className={"actions"}>
          <button className={"btn btn-primary"} onClick={handleSignIn} disabled={signInDisabled}>Sign In</button>
        </div>
      </div>
    </div>
  </div>
};

export default Login;