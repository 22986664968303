import {get, post} from './api';

export function me() {
  return get('/users/me');
}
export function suspendSchedule() {
  return post('/users/suspend-schedule');
}
export function unsuspendSchedule() {
  return post('/users/unsuspend-schedule');
}
