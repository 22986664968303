import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import './Prompt.scss';

const Prompt = forwardRef((props, ref) => {

  const {className = '', onClose, title, children, closable = true} = props;
  const [entering, setEntering] = useState(true);
  const [exiting, setExiting] = useState(false);

  const handleClose = () => {
    if (closable) {
      setExiting(true);
      setTimeout(() => {
        onClose();
      }, 500);
    }
  };

  useImperativeHandle(ref, () => ({
    close() {
      handleClose();
    }
  }));

  useEffect(() => {
    setTimeout(() => {
      setEntering(false);
    }, 500);
  }, []);

  return <div className={"Prompt" + (entering ? ' entering' : '') + (exiting ? ' exiting' : '') + " " + className}>
    <div className="prompt-overlay" onClick={handleClose} />
    <div className="prompt-content">
      <div className={"prompt-header"}>
        <h5>{title}</h5>
        {closable && <button className="close" onClick={handleClose}>&times;</button>}
      </div>
      <div className={"prompt-inner-content"}>
        {children}
      </div>
    </div>
  </div>
});

export default Prompt;
