import BottomNavigation from "./BottomNavigation";
import './Navigation.scss';
import {useEffect, useState} from "react";
import {tabs} from '../../config.js';

const lastScrolls = {};

const Navigation = () => {

  const [activeTab, setActiveTab] = useState(tabs.find(t => t.active === true)?.name || tabs[0].name);
  const [scrollDirection, setScrollDirection] = useState('');
  const [ignoreScrollDirection, setIgnoreScrollDirection] = useState(false);

  const handleTab = tab => {
    setIgnoreScrollDirection(true);
    lastScrolls[activeTab] = window.scrollY;
    setActiveTab(tab);
    setTimeout( () => {
      setIgnoreScrollDirection(false);
    }, 500);
  }

  useEffect(() => {
    let lastScroll = 0;
    const handleScroll = () => {
      if (!ignoreScrollDirection) {
        setScrollDirection(window.scrollY > lastScroll ? 'down' : 'up');
        lastScroll = window.scrollY;
      }
    }
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    }

  }, [ignoreScrollDirection]);

  return <div className={"navigation " + scrollDirection}>
    <div className={"navigation-holder"}>
      {tabs.map(tab => <tab.tab key={tab.name} active={tab.name === activeTab} lastScroll={lastScrolls[activeTab] ?? 0} />)}
    </div>
    <BottomNavigation tabs={tabs} activeTab={activeTab} onTab={handleTab} />
  </div>


};

export default Navigation;