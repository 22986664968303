import './AppBar.scss';
import {forwardRef} from "react";
const AppBar = forwardRef(({actions, className = '', children}, ref) => {
  return <div className={"app-bar " + className} ref={ref}>
    {children ? children : <>
      <div className={"logo"}>Let’s Book!</div>
      {actions && <div className={"app-bar-actions"}>
        {actions}
      </div>}
    </>}
  </div>
});

export default AppBar;