import {useContext, useEffect, useState} from "react";
import Navigation from "./components/layout/Navigation";
import {getSchedules} from "./api/schedule";
import {UserContext, DashboardContext} from "./Contexts";
import {getUpcomingClasses, getWaitlistedClasses} from "./api/gym";

const Dashboard = () => {

  const {user} = useContext(UserContext);

  const [upcomingClasses, setUpcomingClasses] = useState([]);
  const [waitingClasses, setWaitingClasses] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [loading, setLoading] = useState(false);
  const [initialised, setInitialised] = useState(false);

  const handleRefresh = () => {
    load();
  };

  const load = () => {
    setLoading(true);
    const promises = [getSchedules(), getUpcomingClasses(), getWaitlistedClasses()];
    Promise.all(promises).then(values => {
      setSchedules(values[0].data);
      setUpcomingClasses(values[1].data);
      setWaitingClasses(values[2].data);
      setInitialised(true);
      setLoading(false);
    }).catch(() => {
      setLoading(false);
      setTimeout(() => {
        alert('Cannot load your upcoming/scheduled classes. Refresh this page to try again.\n\nIf this problem persist, have you changed your password? If so, go to Settings and change it there.');
      }, 100);
    });
  };

  const dashboardValue = {schedules, upcomingClasses, waitingClasses, initialised, loading, onRefresh: handleRefresh};

  useEffect(() => {
    if (user) {
      load();
    }
  }, [user]);

  return <DashboardContext.Provider value={dashboardValue}>
    <div className={"dashboard"}>
      <Navigation/>
    </div>
  </DashboardContext.Provider>;
};
export default Dashboard;