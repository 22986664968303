import Tab from "../layout/Tab";
import './SettingsTab.scss';
import {useContext, useState} from "react";
import {ConfirmContext, ThemeContext, UserContext} from "../../Contexts";
import {apiLogout} from "../../api/api";
import {suspendSchedule, unsuspendSchedule} from "../../api/user";

import {themes} from '../../config';
import ColorPicker from "./settings/ColorPicker";
import ChangePassword from "./settings/ChangePassword";

const SettingsTab = props => {

  const {user, setUser} = useContext(UserContext);
  const {theme, onTheme} = useContext(ThemeContext);
  const {show: showConfirm} = useContext(ConfirmContext);

  const [showUpdatePassword, setShowUpdatePassword] = useState(false);

  const handleTheme = () => {

    const defaultDark = window.matchMedia('(prefers-color-scheme: dark)').matches;

    if (theme === 'default') {
      // we check if the global is dark or not, then we choose the next one
      onTheme(defaultDark ? 'light' : 'dark');
    } else if (theme === 'dark') {
      onTheme(defaultDark ? 'default' : 'light');
    } else if (theme === 'light') {
      onTheme(defaultDark ? 'dark' : 'default');
    } else {
      // fallback
      onTheme('default');
    }
  }

  const handleSignOut = () => {
    if (window.confirm('Sign out now?')) {
      apiLogout();
      window.location.reload();
    }
  }

  const handleUnsuspend = () => {
    showConfirm({
      title: `Resume Booking?`,
      question: <>
        <p>Do you want to resume booking now?</p>
      </>,
      confirmBtnText: 'Resume Booking',
      confirmBtnClass: '',
      cancelBtnText: 'Cancel',
      cancelBtnClass: 'btn-text',
      onConfirm: () => {
        unsuspendSchedule().then(res => {
          setUser(res.data);
        }).catch(() => {
          alert('Failed to resume booking');
        })
        return true;
      }
    });
  }

  const handleSuspend = () => {
    showConfirm({
      title: `Suspend Account?`,
      question: <>
        <p>Do you want to suspend your account now? This action is reversible at any point of time</p>
      </>,
      confirmBtnText: 'Suspend Account',
      confirmBtnClass: 'btn-danger',
      cancelBtnText: 'Cancel',
      cancelBtnClass: 'btn-text',
      onConfirm: () => {
        suspendSchedule().then(res => {
          setUser(res.data);
        }).catch(() => {
          alert('Failed to suspend account');
        })
        return true;
      }
    });
  }

  return <Tab {...props} className={"settings"}>
    <div className={"items"}>
      <div className={"item"}>
        <div className={"item-header"}>Suspend Account</div>
        {user.schedule_suspended_at ?
          <div className={"item-body"}>
            Your account is suspended. All bookings are put on hold.
            You may want to resume it by clicking the button below:
          </div> : <div className={"item-body"}>
            You can suspend your own account so that your schedules will be put
            on hold until you resume it yourself. This action is reversible.
          </div>}
        <div className={"item-footer"}>
          {user.schedule_suspended_at ?
            <button className={"btn-link"} onClick={handleUnsuspend}>Resume Booking</button> :
            <button className={"btn-link"} onClick={handleSuspend}>Suspend Account</button>}
        </div>
      </div>

      <div className={"item"}>
        <div className={"item-header"}>Currently Signed In As</div>
        <div className={"item-body"}>
          {user.name}
        </div>
      </div>

      <div className={"item"}>
        <div className={"item-header"}>Theme</div>
        <div className={"item-footer"}>
          <button className={"btn-link"} onClick={handleTheme}>{themes[theme]}</button>
        </div>
      </div>

      <div className={"item"}>
        <div className={"item-header"}>Color</div>
        <div className={"item-footer"}>
          <ColorPicker />
        </div>
      </div>

      <div className={"item"}>
        <div className={"item-header"}>Password Changed?</div>
        <div className={"item-body"}>
          <p>If you have changed your password from the FF App, change your password here too:</p>
        </div>
        <div className={"item-footer"}>
          <button className={"btn-link"} onClick={() => setShowUpdatePassword(true)}>Update My Password</button>
        </div>
      </div>

      <div className={"item"}>
        <div className={"item-header"}>About Let’s Book!</div>
        <div className={"item-body"}>
          <p>Well you all know what's this app is so PLEASE KEEP THIS TO YOURSELF ;)</p>
          <p>Cannot stress enough the importance of keeping this low profile. Do enjoy
          this little tool while you can ok? 🙂</p>
        </div>
      </div>
      <div className={"item"}>
        <div className={"item-footer"}>
          <button className={"btn-link"} onClick={handleSignOut}>Sign Out</button>
        </div>
      </div>
    </div>
    <div className={"version"}>
      v5.0.0
    </div>
    {showUpdatePassword && <ChangePassword
      onClose={() => setShowUpdatePassword(false)}
    />}
  </Tab>
}

export default SettingsTab;