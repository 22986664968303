import './Tab.scss';
import AppBar from "./AppBar";
import AccountSuspended from "../AccountSuspended";
import {forwardRef, useContext, useEffect} from "react";
import {UserContext} from "../../Contexts";
const Tab = forwardRef(({children, className, active, actions, appBar, lastScroll}, ref) => {

  const {user} = useContext(UserContext);
  useEffect(() => {
    if (active) {
      window.scrollTo(0, lastScroll);
    }
  }, [lastScroll, active]);


  return <div className={"navigation-tab " + className + (active ? ' active' : '')} ref={ref}>
    {appBar ? appBar : <AppBar actions={actions} />}
    {user.schedule_suspended_at && <AccountSuspended />}
    <div className={"tab-content"}>
      {children}
    </div>
  </div>;
});

export default Tab;